$(function () {
    let formYear = $('#formYear .selectpicker');
    let mediumLength = 0;
    let shouldClearResults = false

    $(document).on('click', '.mobile-nav', function (e) {
        e.preventDefault();
        $('html').toggleClass('mob-nav-active');
    });

    $(document).on('click', '.main-nav > .port-dropdown > a', function (e) {
        e.preventDefault();

        if ($(this).hasClass('active')) {
            $('.main-nav > li > a').removeClass('active');
        } else {
            $('.main-nav > li > a').removeClass('active');
            $(this).addClass('active');
        }
    });


    formYear.selectpicker("render");

    formYear.on('change', function () {
        let year = $(this).val();
        let winner_url = '';
        if (year != '0') {
            if (year == '2012') {
                winner_url = 'http://entrysystemlia.com/winners';
            } else {
                winner_url = 'https://' + year + '.liaentries.com/winners/';
            }
            window.open(winner_url, '_blank');
        }
    });

    function creditInfo() {
        let creditInfoNames = $('.credit-info td strong');

        /* For each credit info td */
        creditInfoNames.each(function () {

            /* Find only the once with dynamic_type_id NOT equal to 4 and with / */
            if ($(this).attr('data-dynamic-type') != '4' && $(this).text().indexOf('/') > 0) {

                if ($(this).attr('data-dynamic-type') != '2') {
                    /* Split them by / */
                    let names = $(this).text().trim().split('/');

                    /* Wrap the names with spans */
                    let wrapNames = names.map(name => {
                        return '<span>' + name.trim() + '</span>';
                    })

                    /* On each td join by / the new html */
                    $(this).html(wrapNames.join(', ').replace(/\, /g, ' / '))
                } else {
                    /* Split them by / */
                    let names = $(this).text().trim().split('/');

                    /* Wrap the names with spans */
                    let wrapNames = names.map(name => {
                        return '<span>' + name.trim() + '</span>';
                    })

                    /* On each td join by / the new html */
                    $(this).html(wrapNames.join(' / '))
                }
            }
        })
    }
    creditInfo();

    function regionalYear() {
        let viewAllRegionsBtn = $('.view-all-regions');
        let panelRegion = $('.panel-region');
        let regionSelectPicker = $('#navigation-region .selectpicker');

        viewAllRegionsBtn.on('click', function () {
            panelRegion.show();
        });

        regionSelectPicker.selectpicker("render");

        regionSelectPicker.on('change', function () {
            let val = $(this).val();
            panelRegion.hide();
            $('#' + val).show();

            if (val == '0') {
                panelRegion.show();
            }
        })
    }
    regionalYear();

    function setSearchResults() {
        $("#searchEngine select").on("changed.bs.select", function (e, clickedIndex, newValue, oldValue) {
            let sessionVal = $(e.target).val();
            let arr = this.id;

            let searchResults = {
                medium: [],
                category: [],
                country: [],
                award: []
            };

            /* If sessionVal is null make it empty array */
            if (sessionVal == null) sessionVal = [];


            $.ajax({
                url: 'search/getSearchResults.cfm',
                success: function (d) {
                    searchResults = JSON.parse(d);
                    /* Get the dynamic session value */
                    searchResults[arr] = sessionVal

                    if (mediumLength > searchResults.medium.length) {
                        searchResults.category = [];
                        searchResults.country = [];
                        shouldClearResults = true;
                    }


                    /* Send the dynamic session value */
                    $.ajax({
                        url: 'search/setSearchResults.cfm',
                        data: searchResults,
                        method: 'POST',
                        success: function (d) {
                            // console.log(d);
                        },
                        error: function (e) {
                            console.log(e);
                        }
                    })
                },
                error: function (e) {
                    console.log(e);
                }
            })
        });
    }
    setSearchResults();

    /* Get the labels on page load */
    function labelsOnLoad(selector) {
        let length = selector.find('option:selected').length;

        /* If selected filters are 0 - removed the html */
        if (length > 0) {
            selector.next().html(length + ' selected');
        } else {
            selector.next().html('');
        }

        /* If there is more than one media selected hide the 'select all' and 'deselect all' btns */
        $('#searchEngine #category').on('loaded.bs.select', function (e, clickedIndex, isSelected, previousValue) {
            let actionBtns = $(this).parent().find('.bs-select-all');
            if ($('#medium-label').text().split(' ')[0] > 1 || $('#medium-label').text() == '') {
                actionBtns.hide();
            } else {
                actionBtns.show();
            }
        });
    }

    /* Change the labels on change event */
    function labelsOnChange(selector) {
        let length = selector.find('option:selected').length;
        let category = $('#searchEngine #category option:selected').length;

        /* If selected filters are 0 - removed the html */
        if (length > 0) {
            selector.parent().next().html(length + ' selected');
        } else {
            selector.parent().next().html('');
        }

        /* If category is empty remove the label */
        if (category <= 0) {
            $('#category-label').html('');
        }

    }


    function labelSelectedSearchFilters() {

        $.ajax({
            url: 'search/getSearchResults.cfm',
            async: false,
            success: function (d) {
                let data = JSON.parse(d);
                let $medium = $("#searchEngine #medium");
                let $category = $("#searchEngine #category");
                let $country = $("#searchEngine #country");
                let $award = $("#searchEngine #award");

                mediumLength = data.medium.length;

                /* Get the value from the session */
                $medium.val(data.medium);
                $category.val(data.category);
                $country.val(data.country);
                $award.val(data.award);

                /* Get the labels */
                labelsOnLoad($medium);
                labelsOnLoad($category);
                labelsOnLoad($country);
                labelsOnLoad($award);

            },
            error: function (e) {
                console.log(e);
            }
        })

        $('body').on('click', function () {
            if ($(this).hasClass('ready-for-search')) {
                if (shouldClearResults) {
                    $('#searchEngine #category option, #searchEngine #country option, #searchEngine #award option').each(function () {
                        $(this).attr('selected', false);
                    })
                }

                $('#searchEngine').submit();
            }
        })

        $('#searchEngine select').on('change', function () {
            $('body').addClass('ready-for-search');
            labelsOnChange($(this))
        });

    }
    labelSelectedSearchFilters();

    function pascalCase(str) {
        return str.split(' ').map(w => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(' ');

    }

    function getAllCategoriesByMedium(medium) {
        let $category = $("#searchEngine #category");

        let result = {
            medium: '',
            categories: []
        };

        $category.find('option:selected').each(function () {
            let category = $(this).text().trim().split(' - ')[0];
            if (medium.toUpperCase() == category) {
                result.medium = pascalCase(category);
                result.categories.push($(this).text().trim().split(' - ')[$(this).text().trim().split(' - ').length - 1])
            }
        })

        return result;
    }

    function getAllMediums() {
        let $medium = $("#searchEngine #medium");

        let result = [];

        $medium.find('option:selected').each(function () {
            result.push(pascalCase($(this).text().trim()))
        })

        return result;
    }

    function checkDuplicates(arr) {
        return [...new Set(arr)];
    }

    function getSearchResultsHeaders() {
        $.ajax({
            url: 'search/getSearchResults.cfm',
            success: function (d) {
                let data = JSON.parse(d);
                let $country = $("#searchEngine #country");
                let $awards = $("#searchEngine #award");
                let $resultsHeader = $('.search-results-header');
                let $countryHeader = $('.search-results-header-countries');
                let $awardsHeader = $('.search-results-header-awards');
                let toBeAnnouncedArr = [];

                /* We push every medium that will be announced to array */
                $('.to-be-announced').each(function () {
                    toBeAnnouncedArr.push($(this).text().toLowerCase().trim());
                })

                /* If it is press or winners mode */
                if ($('body').hasClass('site-mode-press') || $('body').hasClass('site-mode-winners')) {
                    /* Awards */
                    if (data.award.length > 0) {

                        if (data.award.length == 5) {
                            $awardsHeader.html('<h3 style="margin-bottom:0;"><strong>Awards:</strong> All</h3>');
                        } else {
                            $awardsHeader.html('<h3 style="margin-bottom:0;"><strong>Awards:</strong> ' + Array.from($awards.find('option:selected')).map(v => v.innerText).join(' | ') + '</h3>');
                        }

                    } else {
                        $awardsHeader.append(`<h3 style="margin-bottom:0;"><strong>Awards:</strong> All</h3>`);
                    }


                    /* Mediums and categories */
                    for (let m of getAllMediums()) {
                        /* Check if there is something to be announced */
                        let shouldPrint = toBeAnnouncedArr.every((announced) => {
                            return announced != m.toLowerCase();
                        })

                        if (shouldPrint) {
                            /* Show the categories */
                            if (getAllCategoriesByMedium(m).categories != '') {

                                $resultsHeader.append(`<h3 style="margin-bottom:0;"><strong>${m}: </strong>${getAllCategoriesByMedium(m).categories.join(' | ')}</h3>`);
                            } else {
                                /* If the mediums are empty show all */
                                $resultsHeader.append(`<h3 style="margin-bottom:0;"><strong>${m}: </strong>All</h3>`);
                            }
                        }

                    }
                } else {
                    /* If it is shortlist mode */
                    let mediumsSet = new Set();

                    /* Mediums and categories */
                    $('.medium-entry-title').each(function () {
                        mediumsSet.add($(this).text().trim());
                    })

                    /* Mediums and categories */
                    for (let t of mediumsSet) {
                        /* Check if there is something to be announced */
                        let shouldPrint = toBeAnnouncedArr.every((announced) => {
                            return announced != t.toLowerCase();
                        })

                        if (shouldPrint) {
                            /* Show the categories */
                            if (getAllCategoriesByMedium(t).categories != '') {
                                $resultsHeader.append(`<h3 style="margin-bottom:0;"><strong>${t}: </strong>${getAllCategoriesByMedium(t).categories.join(' | ')}</h3>`);
                            } else {
                                /* If the mediums are empty show all */
                                $resultsHeader.append(`<h3 style="margin-bottom:0;"><strong>${t}: </strong>All</h3>`);
                            }
                        }

                    }
                }

                /* Countries */
                if (data.country.length > 0) {
                    if (data.country.length == 230) {
                        $countryHeader.html('<h3 style="margin-bottom:0;"><strong>Countries:</strong> All</h3>');
                    } else {
                        $countryHeader.html('<h3 style="margin-bottom:0;"><strong>Countries:</strong> ' + Array.from($country.find('option:selected')).map(v => v.innerText).join(' | ') + '</h3>');
                    }
                } else {
                    $countryHeader.html('<h3 style="margin-bottom:0;"><strong>Countries:</strong> All</h3>');
                }
            },
            error: function (e) {
                console.log(e);
            }
        })


    }
    getSearchResultsHeaders()

    function campaignTitlesInQuotes() {
        let medium = $('.medium-entry-content .text-pink');

        medium.each(function () {

            if ($(this).text().trim().toLowerCase().indexOf('campaign') > 0) {
                let title = $(this).parent().find('.medium-entry-content-title')

                let quoteTitle = title
                    .text()
                    .split(', ')
                    .map(str => '<span style="white-space: nowrap;">"' + str.trim() + '"</span>')
                    .join(',')
                    .replace(/\,+/g, ', ');

                title.html(quoteTitle);

            }
        })

    }

    campaignTitlesInQuotes();

})